import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  theme,
  Center,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Logo } from './Logo';
import CountDown from './CountDown';

function App() {

  const [count, setCount] = useState(0)

  const [days, setDays] = useState(0)
  const [hrs, setHrs] = useState(0)
  const [mins, setMins] = useState(0)
  const [sec, setSec] = useState(0)

  useEffect(() => {
    setInterval(() => {
      setCount(c => c + 1)
    }, 1000)
  }, [])

  useEffect(() => {
      setSec(Math.floor((1685577600000 - Date.now())/1000) % 60)
      setMins(Math.floor((1685577600000 - Date.now())/(60 * 1000)) % 60)
      setHrs(Math.floor((1685577600000 - Date.now())/(60 * 60 * 1000)) % 24)
      setDays(Math.floor((1685577600000 - Date.now())/(24 * 60 * 60 * 1000)))
  }, [count])

  return (
    <ChakraProvider theme={theme}>
      <Center p="5%" justifyContent={"start"} bgImage={"url('/bg.jpg')"} textAlign="center" w="100%" h="100vh" fontSize="xl">
          <CountDown d={days < 10 ? '0' + days : days} h={hrs < 10 ? '0' + hrs : hrs} m={mins < 10 ? '0' + mins : mins} s={sec < 10 ? '0' + sec : sec}/>
      </Center>
    </ChakraProvider>
  );
}

export default App;
